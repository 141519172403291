import React from "react";
import BaseLayout from "../components/baseLayout";
import { graphql, useStaticQuery } from "gatsby";
import HeroTitle from "../components/heroTitle";
import Link from "gatsby-link";
import { FaLanguage, FaMapMarkerAlt, FaRegClock } from "react-icons/fa";

export default function WorkWithUsPage() {
  // const data = useStaticQuery(graphql`
  //   {
  //     allMarkdownRemark(
  //       filter: { fileAbsolutePath: { regex: "//content/workWithUs//" } }
  //       sort: { order: ASC, fields: [frontmatter___order] }
  //     ) {
  //       edges {
  //         node {
  //           frontmatter {
  //             title
  //             location
  //             type
  //             languages
  //             jobSchedule
  //             path
  //             description
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);
  // const list = data.allMarkdownRemark.edges.map(({ node }) => node.frontmatter);

  const list = [];

  return (
    <BaseLayout>
      <HeroTitle title="Open positions" subtitle="" description="" />
      <div className="section container">
        {list.length > 0 ? (
          list.map((node, i) => <WorkWithUsCard node={node} key={i} />)
        ) : (
          <div
            className="has-text-centered p-6 fontWeight"
            style={{ height: "60dvh" }}
          >
            No open positions at the moment.
          </div>
        )}
      </div>
    </BaseLayout>
  );
}

function WorkWithUsCard({ node }) {
  return (
    <Link to={node.path}>
      <div className="card round responsiveMargin shadow">
        <div className="card-content">
          <div className="columns is-multiline">
            <div className="column">
              <h3 className="is-size-4 has-text-link">{node.title}</h3>
            </div>
          </div>
          <div className="columns is-multiline mb-4">
            <div className="column is-flex">
              <FaMapMarkerAlt className="is-size-5" />
              <span className="ml-1">{node.location}</span>
            </div>
            <div className="column is-flex">
              <FaLanguage className="is-size-4" />
              <span className="ml-1">{node.languages}</span>
            </div>
            <div className="column is-flex">
              <FaRegClock className="is-size-5" />
              <span className="ml-1">{node.jobSchedule}</span>
            </div>
          </div>
          <div className="columns is-multiline">
            <div className="column">
              <p className="is-size-6">{node.description}</p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
